"use client";

import React, { ReactElement } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { assets } from "@koble/assets/assets";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Col, Row, Typography } from "antd";
import Link from "next/link";
import { usePathname } from "next/navigation";

import config from "@/config";

// x-release-please-start-version
const MainLayout = ({ children }: { children: ReactElement }) => {
  const pathName = usePathname();

  const isComplete = pathName.includes("complete");

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={config.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
    >
      <GoogleOAuthProvider clientId={config.NEXT_PUBLIC_GOOGLE_CLIENT_ID}>
        <Row>
          <Col
            xs={24}
            md={isComplete ? 14 : 12}
            lg={isComplete ? 12 : 10}
            xl={isComplete ? 11 : 8}
            xxl={isComplete ? 10 : 7}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Link href="/">
              <div
                style={{
                  position: "absolute",
                  top: 20,
                  left: 20,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <img
                  width={130}
                  src={assets.KOBLE_LOGO_COLOR_WITH_TEXT}
                  alt="Koble logo"
                />
              </div>
            </Link>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              <div
                style={{
                  position: "absolute",
                  bottom: 10,
                  left: 20,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography.Text style={{ color: "#626262", fontSize: 12 }}>
                  V1.39.0
                </Typography.Text>
              </div>
              {children}
            </div>
          </Col>
          <Col
            xs={0}
            md={isComplete ? 10 : 12}
            lg={isComplete ? 12 : 14}
            xl={isComplete ? 13 : 16}
            xxl={isComplete ? 14 : 17}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundImage: `url("${assets.SSO_BACKGROUND}")`,
              backgroundSize: "cover",
              backgroundPosition: "center left",
            }}
          />
        </Row>
      </GoogleOAuthProvider>
    </GoogleReCaptchaProvider>
  );
};

export default MainLayout;
